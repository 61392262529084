import React from "react";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";

import GiftCardView, {
  GiftCardViewWithRecaptcha,
} from "../components/GiftCardView";
import SecurityProvider from "../components/security-provider";
import {
  loadPaymentMethods,
  createGiftCard,
  resetGiftCard,
  getExternalGiftCards,
  addExternalGiftCardToAccount,
  removeExternalGiftCardFromAccount,
  resetAddExternalGiftCardToAccount,
  setGiftCardAmount,
  setGiftCardRecipient,
  setGiftCardSender,
  setGiftCardTextMessage,
} from "../store/user/actions";
import { getCurrentPaymentMethodDetails, getDefaultPaymentMethodDetails, getStyles } from "../store/selectors";
import { openPaymentAction, rechargeCard } from "../store/order/actions";
import { get } from "lodash";
import { openAuthView } from "../store/app/actions";

const GiftCardPage = (props) => {
  const businessAppConfiguration =
    props.pageContext.businessAppConfiguration || {};
  const { useRecaptchaInGiftCard } = businessAppConfiguration;
  const { recaptchaKey, captchaProvider, hcaptchaKey } = businessAppConfiguration;
  const loadWithSecurityProvider = recaptchaKey && useRecaptchaInGiftCard;
  return loadWithSecurityProvider ? (
    <SecurityProvider
      useReacptcha={useRecaptchaInGiftCard}
      recaptchaKey={recaptchaKey}
      captchaProvider={captchaProvider}
      hcaptchaKey={hcaptchaKey}
    >
      <GiftCardViewWithRecaptcha {...props} />
    </SecurityProvider>
  ) : (
    <GiftCardView {...props} />
  );
};

const mapStateToProps = (state, props) => {
  const { user, app, order } = state;
  return {
    user,
    app,
    order,
    appStyles: getStyles(state, props),
    paymentTypeDetails: getDefaultPaymentMethodDetails(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: { business },
  } = props;

  const currency = get(business, "openChargeCard.price.currency", 0);
  const chargeCardBasePrice = get(business, "openChargeCard.price");

  return {
    loadPaymentMethods: (paymentTypeIdentifier) =>
      dispatch(loadPaymentMethods(paymentTypeIdentifier, currency)),
    createGiftCard: (giftCardDetails) =>
      dispatch(createGiftCard(giftCardDetails)),
    resetGiftCard: () => dispatch(resetGiftCard()),
    rechargeCard: (params) =>
      dispatch(
        rechargeCard({
          ...params,
          currency,
          chargeCardBasePrice,
          isGiftCard: true,
        }),
      ),
    getExternalGiftCards: (branchId) =>
      dispatch(getExternalGiftCards(branchId)),
    addExternalGiftCardToAccount: ({ cardNumber, cardPIN, recaptchaToken }) =>
      dispatch(addExternalGiftCardToAccount({ cardNumber, cardPIN, recaptchaToken })),
    resetAddExternalGiftCardToAccount: () =>
      dispatch(resetAddExternalGiftCardToAccount()),
    removeExternalGiftCardFromAccount: ({ cardNumber }) =>
      dispatch(removeExternalGiftCardFromAccount({ cardNumber })),
    openAuthView: (loginType) => dispatch(openAuthView(loginType)),
    setGiftCardAmount: (params) => dispatch(setGiftCardAmount(params)),
    setGiftCardRecipient: (params) => dispatch(setGiftCardRecipient(params)),
    setGiftCardSender: (params) => dispatch(setGiftCardSender(params)),
    setGiftCardTextMessage: (params) => dispatch(setGiftCardTextMessage(params)),
    openPayment: (params) => dispatch(openPaymentAction({...params, currency})),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(GiftCardPage));
