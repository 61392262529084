import _, { get } from "lodash";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import Modal from "../Modal";
import { setParams } from "../../utils/location";
import { ButtonMUI as Button } from "../Button";
import Card from "../Card";
import ScanIcon from "../icons/Scan.svg";
import TrashIcon from "../icons/Trash.svg";
import { TextInput } from "../Inputs";
import List from "../List";
import Loader from "../Loader";
import * as styles from "./index.module.scss";
import withCaptcha from "../../hooks/withCaptcha";

const MyGiftCardView = ({
  appStyles,
  T,
  classNames,
  currencySymbol: appCurrencySymbol,
  user: {
    externalGiftCards,
    addExternalGiftCardState,
    removeExternalGiftCardState,
  },
  addExternalGiftCardToAccount,
  removeExternalGiftCardFromAccount,
  getExternalGiftCards,
  resetAddExternalGiftCardToAccount,
  openInputFields,
  googleReCaptchaProps,
  businessAppConfiguration
}) => {
  
  const [openAddGiftCard, setOpenAddGiftCard] = React.useState(openInputFields);
  const [newGiftCard, setNewGiftCard] = React.useState({});
  const [openQRCodeModal, setOpenQRCodeModal] = React.useState({ open: false, number: undefined });
  if (
    addExternalGiftCardState &&
    !_.isEmpty(newGiftCard) &&
    addExternalGiftCardState.sending
  ) {
    setNewGiftCard({});
    setOpenAddGiftCard(openInputFields);
  } else if (
    (addExternalGiftCardState && addExternalGiftCardState.sent) ||
    (removeExternalGiftCardState && removeExternalGiftCardState.sent)
  ) {
    resetAddExternalGiftCardToAccount();
    getExternalGiftCards();
  }

  console.log("externalGiftCards", externalGiftCards);

  return (
    <div className={classNames}>
      <div style={{ display: "flex", flexDirection: "column", margin: 16 }}>
        <span
          style={{
            color: appStyles.titleOnBackgroundColor,
            textAlign: "center",
            fontSize: "1.3rem",
          }}
        >
          {T(T("{{business_name}} Gift Cards"))}
        </span>
        <List rtl>
          {externalGiftCards &&
            !externalGiftCards.sending &&
            externalGiftCards.data &&
            _.map(
              _.filter(externalGiftCards.data.giftCards, (giftCard) =>
                _.get(giftCard, "enabled"),
              ),
              (giftCard) => {
                return (
                  <List.Item
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row", maxHeight: "40px", cursor: "pointer" }}
                      onClick={() =>
                        setOpenQRCodeModal({ open: true, number: _.get(giftCard, "cardNumber") })
                      }>
                      <span style={{ display: "flex", flexDirection: "row" }} >
                        <ScanIcon
                          role="button"
                          aria-label={T("Show QR Code")}
                          style={{ paddingRight: "10px" }}
                        />
                      </span>

                      <span style={{ display: "flex", flexDirection: "column" }}>
                        <small className={styles.GiftCardLabel}>
                          {T("Card Number")}
                        </small>

                        {_.get(giftCard, "cardNumber")}
                      </span>
                    </div>
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      <small className={styles.GiftCardLabel}>
                        {T("Balance")}
                      </small>
                      {appCurrencySymbol}
                      {_.get(giftCard, "balance")}
                    </span>

                    <span>
                      <TrashIcon
                        onClick={() =>
                          removeExternalGiftCardFromAccount({
                            cardNumber: _.get(giftCard, "cardNumber"),
                          })
                        }
                        role="button"
                        aria-label={T("REMOVE")}
                      />
                    </span>
                  </List.Item>
                );
              },
            )}
        </List>
        {((addExternalGiftCardState && addExternalGiftCardState.sending) ||
          (externalGiftCards && externalGiftCards.sending)) && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <Loader appStyles={appStyles} />
            </div>
          )}
        {openAddGiftCard &&
          (!addExternalGiftCardState || !addExternalGiftCardState.sending) && (
            <Card appStyles={appStyles}>
              <Card.Title light appStyles={appStyles}>
                {T("Enter your gift card number")}
              </Card.Title>
              <Card.Content>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (
                      !_.isEmpty(newGiftCard.cardNumber) &&
                      !_.isEmpty(newGiftCard.cardPIN)
                    ) {
                      if (
                        get(
                          businessAppConfiguration,
                          "useRecaptchaInGiftCard"
                        ) &&
                        googleReCaptchaProps
                      ) {
                        googleReCaptchaProps
                          .executeRecaptcha("giftcard")
                          .then((recaptchaToken) => {
                            addExternalGiftCardToAccount({
                              ...newGiftCard,
                              recaptchaToken,
                            });
                          });
                      } else {
                        addExternalGiftCardToAccount(newGiftCard);
                      }
                    }
                  }}
                >
                  <div style={{ marginBottom: 6 }}>
                    {T("Gift Card Number")}
                  </div>
                  <TextInput
                    onValid={(value) =>
                      setNewGiftCard({ ...newGiftCard, cardNumber: value })
                    }
                    appStyles={appStyles}
                    validator={(input) => !_.isEmpty(input)}
                    errorMessage={T("Please enter a valid gift card number")}
                  />
                  <div style={{ marginBottom: 6 }}>{T("Gift Card PIN")}</div>
                  <TextInput
                    onValid={(value) =>
                      setNewGiftCard({ ...newGiftCard, cardPIN: value })
                    }
                    appStyles={appStyles}
                    noCheckmark
                  />
                  <Button
                    type="submit"
                    style={{ margin: 0, marginTop: "0px" }}
                    appStyles={appStyles}
                  >
                    {T("Connect Gift Card")}
                  </Button>
                </form>
              </Card.Content>
            </Card>
          )}

        {(!addExternalGiftCardState || !addExternalGiftCardState.sending) &&
          !openAddGiftCard && (
            <Button
              style={{ margin: 0, marginTop: "16px" }}
              appStyles={appStyles}
              onClick={() => {
                setOpenAddGiftCard(true);
              }}
            >
              {T("Enter a Gift Card")}
            </Button>
          )}

        <Modal
          open={openQRCodeModal.open}
          onClose={() => {
            setOpenQRCodeModal({ open: false, number: undefined });
          }}
          appStyles={appStyles}
        >
          <h2 style={{ ...appStyles.CardTitle, textAlign: "center" }}>
            {T("Gift Card")}
          </h2>
          <h4 style={{ ...appStyles.CardTitle, textAlign: "center" }}>
            {T("Scannable QR Code")}
          </h4>
          
          <div>
            {_.get(openQRCodeModal, "number") && (
              <QRCodeSVG
                className="fs-unmask"
                value={
                  openQRCodeModal.number
                }
                style={{ height: "100%", width: "100%", maxWidth: "300px", maxHeight:"300px"}}
              />)}

          </div>

          <Button
              style={{ margin: 0, marginTop: "16px" }}
              appStyles={appStyles}
              centered
              to = {setParams("/gift-card", {"reloadCardNumber": openQRCodeModal.number, onCloseTarget: "/"})}
            >
              {T("Add funds to this card")}
            </Button>
        </Modal>
      </div>
    </div>
  );
};

export default withCaptcha(MyGiftCardView);